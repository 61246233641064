import React from "react";
import "./styles.scss";

export const ScrollList = () => {
  return (
    <div className="containerScrollList">
      <div className="containerScrollList--wrapper">
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
        <div className="containerScrollList--wrapper__label">
          <p>Dr. Strangelove</p>
          <p className="rectangle">7.8</p>
        </div>
      </div>
    </div>
  );
};
